import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	loginUser,
	resetUserModalData,
	sendLoginOTP,
	setResendOTPTime,
	setUserModalData,
	setUserModalError,
	setUserModalLoading,
} from '@/store/actions';

// utils
import * as eventActions from '@/utils/analytics';
import { getOTPPayload } from '@/utils/auth';
import redirectToPlatform from '@/utils/redirect-to-platform';

export default function WithAuthUtils(Component, analyticsProps) {
	const WrappedComponent = (props) => {
		const dispatch = useDispatch();

		const authConstants = useSelector((state) =>
			get(state, 'cmsGlobalData.data.globalFormConfig.authConstants')
		);
		const userModalState = useSelector((state) => state.userModal);
		const userModalProps = { ...userModalState, ...userModalState.data };

		const [isResendOtp, setResendOTP] = React.useState(userModalProps.otpSent);
		const [values, setValues] = React.useState({
			username: userModalProps.phoneNumber || '',
			otp: '',
		});
		const [errorTexts, setErrorTexts] = React.useState({
			username: '',
			otp: '',
		});

		const trackEvent = (eventName, eventProperties) => {
			eventActions.trackEvent(eventName, {
				section:
					userModalProps.modalType &&
					userModalProps.modalType.toString().toUpperCase() + '_MODAL',
				...(analyticsProps || {}),
				...eventProperties,
			});
		};

		const requestOTP = async (phoneNumber, resend, cb) => {
			dispatch(setUserModalError(''));
			dispatch(setUserModalLoading(true));

			const data = getOTPPayload(phoneNumber, resend, authConstants, location);
			let sendOtpResult = await dispatch(sendLoginOTP(data));
			if (sendOtpResult.success) {
				dispatch(setUserModalData({ phoneNumber, otpSent: true }));
				setResendOTP(true);
				dispatch(setResendOTPTime(30));
				cb && cb();
			} else {
				dispatch(setUserModalError(sendOtpResult.errorMessage));
			}

			dispatch(setUserModalLoading(false));
			return sendOtpResult;
		};

		const resendOTP = (cb) => {
			setResendOTP(!isResendOtp);
			if (validateForm(false)) return requestOTP(values.username, true, cb);
		};

		const sendOTP = (cb) => {
			if (validateForm(false)) return requestOTP(values.username, false, cb);
		};

		const handleSubmit = async () => {
			if (validateForm(true)) {
				dispatch(setUserModalError(''));
				dispatch(setUserModalLoading(true));

				const payload = { username: `+91${values.username}`, otp: values.otp };
				if (location.pathname.startsWith('/kiosk/')) {
					payload.origin = 'Kiosk';
				}

				const redirectionLink = userModalProps.redirectionLink;
				let loginResult = await dispatch(
					loginUser(payload, redirectionLink.startsWith('/'))
				);

				if (loginResult.success == false) {
					dispatch(setUserModalError(loginResult.errorMessage));
				} else {
					if (userModalProps.postSuccessAnalyticsProps) {
						trackEvent(
							userModalProps.postSuccessAnalyticsProps.eventName,
							userModalProps.postSuccessAnalyticsProps
						);
					}

					trackEvent('MOBILE_VERIFY_SUCCESS', {
						eventAction: 'MOBILE_VERIFY_SUCCESS',
						eventLabel: 'Mobile number verified',
						eventCategory: 'LEAD',
					});

					if (redirectionLink) {
						if (isExternalUrl(redirectionLink)) {
							goToExternalRoute(redirectionLink, null, {}, null, false);
						} else {
							dispatch(goToRoute(redirectionLink));
						}
					} else {
						redirectToPlatform(loginResult.data);
					}
				}
				dispatch(setUserModalLoading(false));
				return loginResult;
			}
		};

		const validateForm = (validateOTP) => {
			const validations = { username: '', otp: '' };
			if (values.username.length === 0)
				validations.username = 'Please Enter Your Mobile Number';
			if (values.username.length > 10 || values.username.length < 10)
				validations.username = 'Please Enter Valid Mobile Number';
			if (validateOTP && values.otp.length !== 6)
				validations.otp = 'Please Enter The 6 Digit OTP Sent To You';

			setErrorTexts(validations);

			if (validations.username || validations.otp) return false;
			return true;
		};

		const setNumber = (value) => {
			if (value.length < 11) {
				setValues((exisitngValue) => ({ ...exisitngValue, username: value }));
			}
		};

		const setOTP = (otp) => {
			setValues((exisitngValue) => ({ ...exisitngValue, otp }));
		};

		const reset = () => {
			dispatch(resetUserModalData());
			setValues({ username: '', otp: '' });
			setResendOTP(false);
			setErrorTexts({ username: '', otp: '' });
		};

		React.useEffect(() => {
			setValues((exisitngValue) => ({
				...exisitngValue,
				username: userModalProps.phoneNumber,
			}));
		}, [userModalProps.phoneNumber]);

		return (
			<Component
				{...props}
				setNumber={setNumber}
				setOTP={setOTP}
				resendOTP={resendOTP}
				sendOTP={sendOTP}
				handleSubmit={handleSubmit}
				errorTexts={errorTexts}
				isResendOtp={isResendOtp}
				values={values}
				otpSent={userModalProps.otpSent}
				remainingTime={userModalProps.remainingTime}
				loading={userModalProps.loading}
				modalErrorMessage={userModalProps.error}
				reset={reset}
			/>
		);
	};

	return WrappedComponent;
}
